<template>
  <v-container>
    <v-card class="mt-2 pa-3" color="#fafafa">
      <div class="ma-3 d-flex flex-no-wrap justify-space-between">
        <div class="ma-1">
          <v-form ref="credenciales">
            <div class="justify-center">
              <v-card-text class="py-2 black--text text-center">
                <v-row
                  align="end"
                  class="align-end justify-center"
                  justify="center"
                >
                  <img
                    width="100"
                    height="50"
                    src="@/assets/logo-indac-alt-filled.png"
                  />
                  <strong>{{ $store.getters.version }}</strong>
                </v-row>
              </v-card-text>
            </div>
            <br />
            <v-card-text style="width: 308px">
              <p class="font-weight-bold" style="font-size: 20px">
                Recuperar Cuenta
              </p>
              <br />
              <v-text-field
                prepend-inner-icon="mdi-account"
                v-model="nombreUsuario"
                placeholder="Usuario (RNC / RUT)"
                :rules="rules.notNull"
                required
              ></v-text-field>
              <v-text-field
                prepend-inner-icon="mdi-barcode"
                v-model="captCha"
                placeholder="Escribe el texto que ves en la imagen"
                :rules="rules.notNull"
                required
              ></v-text-field>
              <v-row justify="center">
                <img :src="captChaURL" alt="" />
              </v-row>
            </v-card-text>

            <v-btn
              block
              large
              depressed
              color="primary"
              class="mb-2"
              @click="next"
              >Continuar<v-icon right>mdi-chevron-right</v-icon></v-btn
            >
            <a href="#/login" style="font-size: 1.15rem; color: #009dc4"
              >Volver atras</a
            >
          </v-form>

          <v-snackbar
            :timeout="0"
            v-model="show"
            :multi-line="true"
            :color="color"
            >{{ mensaje }}</v-snackbar
          >
        </div>

        <v-avatar size="65%" tile v-if="!$vuetify.breakpoint.mobile">
          <v-img :src="require('@/assets/portada.jpg')"></v-img>
        </v-avatar>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import api from "@/api/autenticacion";
export default {
  data: () => {
    return {
      show: false,
      color: "info",
      mensaje: "",
      captChaURL: "",
      nombreUsuario: "",
      captCha: "",
      captChaGenerated: "",
      captChatValidator: false,
      rules: {
        notNull: [(v) => !!v || "Este campo es requerido"],
      },
      window: {
        height: null,
      },
    };
  },
  computed: {},
  methods: {
    generate() {
      api.generateCaptCha().then((response) => {
        self.isExporting = false;
        if (response !== null) {
          this.captChaGenerated = response.data.codigo;
          this.captChaURL = `data:image/jpeg;base64, ${response.data.img}`;
        }
      });
    },
    next() {
      this.validacionCaptcha = false;
      if (this.$refs.credenciales.validate()) {
        if (this.captCha === this.captChaGenerated) {
          var data = new FormData();
          data.append("nombreUsuario", this.nombreUsuario);
          api
            .recuperarClave(data)
            .then(() => {
              this.mensaje =
                "Favor continuar el proceso de recuperación de credenciales confirmando el correo que le hemos enviado a su email.";
              this.show = true;
              this.$refs.credenciales.reset();
              setTimeout(() => this.$router.push({ path: "/" }), 5000);
            })
            .catch((error) => {
              this.color = "error";
              this.mensaje = error.response.data.Message;
              this.show = true;
              this.$refs.credenciales.reset();
              setTimeout(() => this.$router.push({ path: "/" }), 5000);
            });
        } else {
          this.captCha = "";
          this.generate();
        }
      }
    },
    handleResize() {
      this.window.height = window.innerHeight;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.generate();
  },
  create() {
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.container.text-xs-center {
  padding-left: 0px;
  padding-right: 0px;
}
</style>

